.mask{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.intro-img {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mask::after{
  content:"";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  
}

.hero{
  height: 100%;
  width: 100%;
}

.hero .content{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  text-align: center;
  padding-left: 100;
}
.hero .content h1{
  font-size: 3rem;
  padding: 0.6rem 0 1.5rem;
  color: rgb(243, 242, 242);

}

.hero .content p{
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(246, 245, 245);
  text-transform: uppercase;
  font-size: 1rem;



}

.content .btn{
  margin: 1rem 0.2rem;
  position: relative;
  top: 1rem;; /*/buttons here /*/
  
  
  
}

@media screen and(max-width:640px) {
  .hero .content h1{
    font-size: 3rem;
  
  
  }
  
  .hero .content p{
    font-size: 1.4rem;
    
   
  
  }

  
} 
