.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2); /* Transparent black background */
    backdrop-filter: blur(10px); /* Adds the blur effect */
    transition: background-color 0.5s ease; /* Smooth transition */
}

.header-bg{
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.5s;
}

.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger{
    display: none;
}

@media screen and (max-width: 1040px) {
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        top: 0;
        left: -100%; /* Hidden by default */
        z-index: 3;
        transition: left 0.3s ease; /* Transition only the 'left' property */
    }

    .nav-menu.active{
        left: 0;
    }

    .nav-menu li{
        padding: 1rem 0;
    }

    .nav-menu li a{
        font-size: 2rem;
    }

    .hamburger{
        display: initial;
    }
}
